//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { getLanguageCode } from '@/i18n';
// import Message from '@/shared/message/message';
import TripCancellationFeesForm from '@/modules/settings/components/trip-cancellation-fees-form';
import ReservationCancellationFeesForm from '@/modules/settings/components/reservation-cancellation-fees-form';
// import FreelanceGuideCancellationFeesForm from '@/modules/settings/components/freelanceGuide-cancellation-fees-form';

import { FormSchema } from '@/shared/form/form-schema';
import { SettingsModel } from '@/modules/settings/settings-model';
const { fields } = SettingsModel;
const formSchema = new FormSchema([
  fields.theme,
  fields.defaultLanguage,
  fields.notificationLanguage,
  fields.paymentMethods,
  fields.saryahFees,
  fields.cancellationTrip,
  fields.cancellationReservation,

  fields.vatPercentage,
]);

export default {
  name: 'app-saryah-cancellation-fees-modal',
  props: ['visible', 'value'],

  data() {
    return {
      language: getLanguageCode(),
      tab: 'client-cancellation-fees',
      // notes: null,
    };
  },
  components:{
    [TripCancellationFeesForm.name]: TripCancellationFeesForm,
    [ReservationCancellationFeesForm.name]: ReservationCancellationFeesForm,
    // [FreelanceGuideCancellationFeesForm.name]: FreelanceGuideCancellationFeesForm

  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      settings:'settings/settings',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    fields() {
      return fields;
    },
  },
  methods: {
    ...mapActions({
      // doFindSettings:'settings/doFind',
      // isItemExist:'product/list/isItemExist'
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    doClose() {
      this.$emit('close')
    },
    updateValue(value) {
      this.$emit('input', value);
    },
    async doSubmit() {
      await this.$emit('submit')
      this.$emit('close')
    },
  },
  created(){
    console.log(this.value);
    this.model = formSchema.initialValues(this.settings);

    this.model.saryahFees = {
      company: this.model.saryahFees.company * 100,
      guide: this.model.saryahFees.guide * 100,
    }
    this.model.vatPercentage = this.model.vatPercentage ? this.model.vatPercentage * 100 : null

    // this.model.clientCancellationFees = {
    //   cancellationPeriod: this.model.clientCancellationFees ? this.model.clientCancellationFees.cancellationPeriod : null,
    //   percentageBeforeCancellationPeriod: this.model.clientCancellationFees ? this.model.clientCancellationFees.percentageBeforeCancellationPeriod * 100 : null,
    //   percentageAfterCancellationPeriod: this.model.clientCancellationFees ? this.model.clientCancellationFees.percentageAfterCancellationPeriod * 100 : null
    // }

    // this.model.companyCancellationFees = {
    //   cancellationPeriod: this.model.companyCancellationFees ? this.model.companyCancellationFees.cancellationPeriod : null,
    //   percentageBeforeCancellationPeriod: this.model.companyCancellationFees ?  this.model.companyCancellationFees.percentageBeforeCancellationPeriod * 100 : null,
    //   percentageAfterCancellationPeriod: this.model.companyCancellationFees ? this.model.companyCancellationFees.percentageAfterCancellationPeriod * 100 : null
    // }

    // this.model.freelanceGuideCancellationFees = {
    //   cancellationPeriod: this.model.freelanceGuideCancellationFees ? this.model.freelanceGuideCancellationFees.cancellationPeriod : null,
    //   percentageBeforeCancellationPeriod: this.model.freelanceGuideCancellationFees ? this.model.freelanceGuideCancellationFees.percentageBeforeCancellationPeriod * 100 : null,
    //   percentageAfterCancellationPeriod: this.model.freelanceGuideCancellationFees ?  this.model.freelanceGuideCancellationFees.percentageAfterCancellationPeriod * 100 : null
    // }
    // console.log(this.model);
    // debugger
  }
};
