import { i18n } from '@/i18n';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import StringArrayField from '@/shared/fields/string-array-field';
import JsonField from '@/shared/fields/json-field';
import DecimalField from '@/shared/fields/decimal-field';

const themes = [
  {
    id: 'default',
    hex: '#409EFF',
  },
  {
    id: 'cyan',
    hex: '#13c2c2',
  },
  {
    id: 'geek-blue',
    hex: '#2f54eb',
  },
  {
    id: 'gold',
    hex: '#faad14',
  },
  {
    id: 'lime',
    hex: '#a0d911',
  },
  {
    id: 'magenta',
    hex: '#eb2f96',
  },
  {
    id: 'orange',
    hex: '#fa8c16',
  },
  {
    id: 'polar-green',
    hex: '#52c41a',
  },
  {
    id: 'purple',
    hex: '#722ed1',
  },
  {
    id: 'red',
    hex: '#f5222d',
  },
  {
    id: 'volcano',
    hex: '#fa541c',
  },
  {
    id: 'yellow',
    hex: '#fadb14',
  },
].map((theme) => ({
  ...theme,
  label: i18n(`settings.colors.${theme.id}`),
}));

function label(name) {
  return i18n(`settings.fields.${name}`);
}

const fields = {
  theme: new EnumeratorField('theme', label('theme'),
    themes,
    { required: true },
  ),
  defaultLanguage: new StringField('defaultLanguage', label('defaultLanguage'),{}),
  notificationLanguage: new StringArrayField('notificationLanguage', label('notificationLanguage'), { required: true }),
  saryahFees: new JsonField('saryahFees', label('saryahFees')),

  cancellationTrip: new JsonField('cancellationTrip', label('cancellationTrip')),
  cancellationReservation: new JsonField('cancellationReservation', label('cancellationReservation')),
  // saryahCancellationFees: new JsonField('saryahCancellationFees', label('saryahCancellationFees')),
  // clientCancellationFees: new JsonField('clientCancellationFees', label('clientCancellationFees')),
  // companyCancellationFees: new JsonField('companyCancellationFees', label('companyCancellationFees')),
  // freelanceGuideCancellationFees: new JsonField('freelanceGuideCancellationFees', label('freelanceGuideCancellationFees')),
  companyWalletLimit: new DecimalField('companyWalletLimit', label('companyWalletLimit')),
  clientWalletLimit: new DecimalField('clientWalletLimit', label('clientWalletLimit')),
  guideWalletLimit: new DecimalField('guideWalletLimit', label('guideWalletLimit')),

  

  paymentMethods: new JsonField('paymentMethods', label('paymentMethods')),
  vatPercentage: new DecimalField('vatPercentage', label('vatPercentage'), {
    min: 0,
    max: 1,
  })
};

export class SettingsModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
