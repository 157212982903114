//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'app-reservation-cancellation-fees-form',
  props: ['value'],
  data() {
    return {
      cancellationFee: {
        period: '',
        percentage: '',
      },
    };
  },
  methods: {
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    addCancellationPeriod() {
      this.value.fees.push(this.cancellationFee);
      this.cancellationFee = {};
    },
    removeCancellationPeriod(index) {
      this.value.fees.splice(index, 1);
    },
  },
};
