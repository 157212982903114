//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SettingsModel } from '@/modules/settings/settings-model';
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import SettingsToolbar from '@/modules/settings/components/settings-toolbar.vue';
import SaryahFeesModal from '@/modules/settings/components/saryah-fees-modal';
import SaryahCancellationFeesModal from '@/modules/settings/components/saryah-cancellation-fees-modal';
import SaryahVatModal from '@/modules/settings/components/saryah-vat-modal';
import SaryahPaymentModal from '@/modules/settings/components/saryah-payment-modal';
import SaryahWalletLimitModal from '@/modules/settings/components/saryah-wallet-limit-modal';

const { fields } = SettingsModel;

const formSchema = new FormSchema([
  fields.theme,
  fields.defaultLanguage,
  fields.notificationLanguage,
  fields.paymentMethods,
  fields.saryahFees,
  // fields.saryahCancellationFees,
  fields.cancellationTrip,
  fields.cancellationReservation,
  // fields.freelanceGuideCancellationFees,

  fields.companyWalletLimit,
  fields.guideWalletLimit,
  fields.clientWalletLimit,

  fields.vatPercentage,
]);

export default {
  name: 'app-settings-page',

  components: {
    [SettingsToolbar.name]: SettingsToolbar,
    [SaryahFeesModal.name]: SaryahFeesModal,
    [SaryahCancellationFeesModal.name]: SaryahCancellationFeesModal,
    [SaryahPaymentModal.name]: SaryahPaymentModal,
    [SaryahVatModal.name]: SaryahVatModal,
    [SaryahWalletLimitModal.name]: SaryahWalletLimitModal,
  },

  async created() {
    // await this.doFind();
    await this.doFindByVuexfire();
    this.model = formSchema.initialValues(this.settings);
    this.model.saryahFees = {
      company: this.model.saryahFees.company * 100,
      guide: this.model.saryahFees.guide * 100,
    };
    this.model.vatPercentage = this.model.vatPercentage
      ? this.model.vatPercentage * 100
      : null;

    this.model.cancellationTrip = {
      percentage: this.model.cancellationTrip
        ? this.model.cancellationTrip * 100
        : null,
    };

    this.model.cancellationReservation = {
      default:
        this.model.cancellationReservation &&
        this.model.cancellationReservation.default
          ? this.model.cancellationReservation.default *
            100
          : null,
      fees:
        this.model.cancellationReservation &&
        this.model.cancellationReservation.fees.length
          ? this.model.cancellationReservation.fees
          : [
              {
                period: '',
                percentage: '',
              },
            ],
    };

    // this.model.clientCancellationFees = {
    //   cancellationPeriod: this.model.clientCancellationFees ? this.model.clientCancellationFees.cancellationPeriod : null,
    //   percentageBeforeCancellationPeriod: this.model.clientCancellationFees ? this.model.clientCancellationFees.percentageBeforeCancellationPeriod * 100 : null,
    //   percentageAfterCancellationPeriod: this.model.clientCancellationFees ? this.model.clientCancellationFees.percentageAfterCancellationPeriod * 100 : null
    // }

    // this.model.companyCancellationFees = {
    //   cancellationPeriod: this.model.companyCancellationFees ? this.model.companyCancellationFees.cancellationPeriod : null,
    //   percentageBeforeCancellationPeriod: this.model.companyCancellationFees ?  this.model.companyCancellationFees.percentageBeforeCancellationPeriod * 100 : null,
    //   percentageAfterCancellationPeriod: this.model.companyCancellationFees ? this.model.companyCancellationFees.percentageAfterCancellationPeriod * 100 : null
    // }

    // this.model.freelanceGuideCancellationFees = {
    //   cancellationPeriod: this.model.freelanceGuideCancellationFees ? this.model.freelanceGuideCancellationFees.cancellationPeriod : null,
    //   percentageBeforeCancellationPeriod: this.model.freelanceGuideCancellationFees ? this.model.freelanceGuideCancellationFees.percentageBeforeCancellationPeriod * 100 : null,
    //   percentageAfterCancellationPeriod: this.model.freelanceGuideCancellationFees ?  this.model.freelanceGuideCancellationFees.percentageAfterCancellationPeriod * 100 : null
    // }

    console.log(this.model);
  },
  mounted() {
    let languages = require('language-list')();
    // this.language = languages.getData().sort((a, b) => {
    //   var x = a.language.toLowerCase();
    //   var y = b.language.toLowerCase();
    //   if (x < y) {return -1;}
    //   if (x > y) {return 1;}
    //   return 0;
    // });
    this.language = languages
      .getLanguageCodes()
      .map((item) => {
        return {
          key: item,
          value: item,
          label: languages.getLanguageName(item),
        };
      })
      .sort((a, b) => {
        var x = a.label.toLowerCase();
        var y = b.label.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

    console.log(
      languages.getData().sort((a, b) => {
        var x = a.language.toLowerCase();
        var y = b.language.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      }),
    );
    // console.log('Sorting : ', this.language);
    // console.log('Sorting : ', languages.getLanguageNames());
  },
  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      language: [],
      feesDialogVisible: false,
      feesModalVisible: false,
      cancellationFeesModalVisible: false,
      cancellationFeesDialogVisible: false,
      walletLimitModalVisible: false,
      walletLimitDialogVisible: false,
      paymentDialogVisible: false,
      paymentModalVisible: false,
      vatDialogVisible: false,
      vatModalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      is_screen_xs: 'layout/is_screen_xs',
      settings: 'settings/settings',
      findLoading: 'settings/findLoading',
      saveLoading: 'settings/saveLoading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
  },
  methods: {
    ...mapActions({
      doFind: 'settings/doFind',
      doFindByVuexfire: 'settings/doFindByVuexfire',
      doSave: 'settings/doSave',
    }),
    i18n(key, args) {
      // return i18n(code);
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.settings);
    },
    doOpenModal(type) {
      switch (type) {
        case 'vat':
          this.vatModalVisible = true;
          this.vatDialogVisible = true;
          break;
        case 'payment':
          this.paymentModalVisible = true;
          this.paymentDialogVisible = true;
          break;
        case 'fees':
          this.feesModalVisible = true;
          this.feesDialogVisible = true;
          break;
        case 'cancellationFees':
          this.cancellationFeesModalVisible = true;
          this.cancellationFeesDialogVisible = true;
          break;
        case 'walletLimit':
          this.walletLimitModalVisible = true;
          this.walletLimitDialogVisible = true;
          break;
      }
    },
    onModalClose() {
      this.vatDialogVisible = false;
      this.paymentDialogVisible = false;
      this.feesDialogVisible = false;
      this.cancellationFeesDialogVisible = false;
      setTimeout(() => {
        this.vatModalVisible = false;
        this.paymentModalVisible = false;
        this.feesModalVisible = false;
        this.cancellationFeesModalVisible = false;
        this.walletLimitModalVisible = false;
      }, 200);
    },
    async doSubmit() {
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }

      const values = formSchema.cast(this.model);
      values.saryahFees = {
        company: this.model.saryahFees.company / 100,
        guide: this.model.saryahFees.guide / 100,
      };
      // values.saryahCancellationFees = {
      //   cancellationPeriod: parseInt(this.model.saryahCancellationFees.cancellationPeriod),
      //   percentageBeforeCancellationPeriod: this.model.saryahCancellationFees.percentageBeforeCancellationPeriod / 100,
      //   percentageAfterCancellationPeriod: this.model.saryahCancellationFees.percentageAfterCancellationPeriod / 100
      // }

      // values.clientCancellationFees = {
      //   cancellationPeriod: parseInt(
      //     this.model.clientCancellationFees
      //       .cancellationPeriod,
      //   ),
      //   percentageBeforeCancellationPeriod:
      //     this.model.clientCancellationFees
      //       .percentageBeforeCancellationPeriod / 100,
      //   percentageAfterCancellationPeriod:
      //     this.model.clientCancellationFees
      //       .percentageAfterCancellationPeriod / 100,
      // };

      // values.companyCancellationFees = {
      //   cancellationPeriod: parseInt(
      //     this.model.companyCancellationFees
      //       .cancellationPeriod,
      //   ),
      //   percentageBeforeCancellationPeriod:
      //     this.model.companyCancellationFees
      //       .percentageBeforeCancellationPeriod / 100,
      //   percentageAfterCancellationPeriod:
      //     this.model.companyCancellationFees
      //       .percentageAfterCancellationPeriod / 100,
      // };

      // values.freelanceGuideCancellationFees = {
      //   cancellationPeriod: parseInt(
      //     this.model.freelanceGuideCancellationFees
      //       .cancellationPeriod,
      //   ),
      //   percentageBeforeCancellationPeriod:
      //     this.model.freelanceGuideCancellationFees
      //       .percentageBeforeCancellationPeriod / 100,
      //   percentageAfterCancellationPeriod:
      //     this.model.freelanceGuideCancellationFees
      //       .percentageAfterCancellationPeriod / 100,
      // };

      values.cancellationTrip = parseInt(this.model.cancellationTrip.percentage) / 100
      var feesArray = []
      this.model.cancellationReservation.fees.forEach(element => {
          var period = parseInt(element.period)
          var percentage = parseInt(element.percentage)/100
          feesArray.push({period,percentage})
        })
      values.cancellationReservation = {
        default: parseInt(this.model.cancellationReservation.default) / 100,
        fees: feesArray
      };

      values.vatPercentage = this.model.vatPercentage
        ? this.model.vatPercentage / 100
        : null;
      values.defaultLanguage = this.currentLanguageCode;
      delete values.paymentMethods.__typename;

      console.log(values);
      // debugger
      return this.doSave(values);
    },
  },
};
