import { render, staticRenderFns } from "./company-cancellation-fees-form.vue?vue&type=template&id=f1e3c85a&scoped=true&"
import script from "./company-cancellation-fees-form.vue?vue&type=script&lang=js&"
export * from "./company-cancellation-fees-form.vue?vue&type=script&lang=js&"
import style0 from "./company-cancellation-fees-form.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./company-cancellation-fees-form.vue?vue&type=style&index=1&id=f1e3c85a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1e3c85a",
  null
  
)

export default component.exports
import {QCard,QCardSection,QIcon,QInput,QField,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QIcon,QInput,QField})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
