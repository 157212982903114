import { render, staticRenderFns } from "./saryah-wallet-limit-modal.vue?vue&type=template&id=859bfb24&scoped=true&"
import script from "./saryah-wallet-limit-modal.vue?vue&type=script&lang=js&"
export * from "./saryah-wallet-limit-modal.vue?vue&type=script&lang=js&"
import style0 from "./saryah-wallet-limit-modal.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./saryah-wallet-limit-modal.vue?vue&type=style&index=1&id=859bfb24&lang=scss&scoped=true&"
import style2 from "./saryah-wallet-limit-modal.vue?vue&type=style&index=2&id=859bfb24&lang=scss&scoped=true&"
import style3 from "./saryah-wallet-limit-modal.vue?vue&type=style&index=3&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "859bfb24",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QIcon,QInput,QTabPanels,QTabPanel,QTabs,QTab,QField,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QIcon,QInput,QTabPanels,QTabPanel,QTabs,QTab,QField})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
