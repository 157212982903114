import { render, staticRenderFns } from "./saryah-cancellation-fees-modal.vue?vue&type=template&id=b3039b4a&scoped=true&"
import script from "./saryah-cancellation-fees-modal.vue?vue&type=script&lang=js&"
export * from "./saryah-cancellation-fees-modal.vue?vue&type=script&lang=js&"
import style0 from "./saryah-cancellation-fees-modal.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./saryah-cancellation-fees-modal.vue?vue&type=style&index=1&id=b3039b4a&lang=scss&scoped=true&"
import style2 from "./saryah-cancellation-fees-modal.vue?vue&type=style&index=2&id=b3039b4a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3039b4a",
  null
  
)

export default component.exports
import {QDialog,QCard,QTabPanels,QTabPanel,QCardSection,QTabs,QTab,QCardActions,QBtn,QField,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QTabPanels,QTabPanel,QCardSection,QTabs,QTab,QCardActions,QBtn,QField})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
