import { render, staticRenderFns } from "./trip-cancellation-fees-form.vue?vue&type=template&id=bc9bfef8&scoped=true&"
import script from "./trip-cancellation-fees-form.vue?vue&type=script&lang=js&"
export * from "./trip-cancellation-fees-form.vue?vue&type=script&lang=js&"
import style0 from "./trip-cancellation-fees-form.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./trip-cancellation-fees-form.vue?vue&type=style&index=1&id=bc9bfef8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc9bfef8",
  null
  
)

export default component.exports
import {QCard,QCardSection,QIcon,QInput,QField,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QIcon,QInput,QField})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
