var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-card',{staticClass:"my-card full-width",attrs:{"flat":""}},[_c('q-card-section',{staticClass:"fees-header"},[_c('app-i18n',{attrs:{"code":"settings.fields.reservationCancellationFees"}}),_c('q-icon',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"cursor-pointer float-right",attrs:{"flat":"","name":"img:/images/icons/ic-close.svg","size":"1rem"}})],1),_c('q-card-section',{staticClass:"fees-message"},[_c('app-i18n',{attrs:{"code":"settings.cancellationFeesPeriod"}})],1),_c('q-card-section',{staticClass:"fees-input"},[_c('div',{staticClass:"row justify-center"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding"},[_c('app-i18n',{staticClass:"fees-label",attrs:{"code":"settings.fields.defaultFees"}}),_c('q-input',{staticClass:"field_input_PinCode q-pt-sm",attrs:{"dense":"","standout":"","autofocus":"","clearable":"","no-error-icon":"","bg-color":"white","color":"AstronautBlue","type":"number","min":"0","max":"100","placeholder":_vm.i18n('settings.placeholder.percentage'),"lazy-rules":"","rules":[
            function (val) { return !val ||
              val > -1 ||
              _vm.i18n('errors.minValue', 0); },
            function (val) { return !val ||
              val <= 100 ||
              _vm.i18n('errors.maxValue', 100); } ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"img:/images/icons/ic-percentage-2.svg","size":"1.25rem","color":"black"}})]},proxy:true}]),model:{value:(_vm.value.default),callback:function ($$v) {_vm.$set(_vm.value, "default", $$v)},expression:"value.default"}})],1)])]),_c('q-card-section',{staticClass:"fees-message"},[_c('app-i18n',{attrs:{"code":"settings.cancellationFeesNoteBefore"}})],1),_c('q-card-section',{staticClass:"fees-input"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-5 col-md-6 col-sm-6 col-xs-6 q-pr-sm"},[_c('app-i18n',{staticClass:"fees-label",attrs:{"code":"settings.fields.durationInDays"}})],1),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 col-xs-6"},[_c('app-i18n',{staticClass:"fees-label",attrs:{"code":"settings.fields.percentage"}})],1)]),_vm._l((_vm.value.fees.length),function(index){return _c('div',{key:index},[_c('div',{staticClass:"row"},[_c('q-input',{staticClass:"field_input_PinCode q-pt-sm col-lg-5 col-md-5 col-sm-5 col-xs-5 q-pr-sm",attrs:{"dense":"","standout":"","clearable":"","no-error-icon":"","bg-color":"white","color":"AstronautBlue","type":"number","min":"0","max":"100","placeholder":_vm.i18n('settings.placeholder.numberOfDays'),"lazy-rules":"","rules":[
            function (val) { return !val ||
              val > -1 ||
              _vm.i18n('errors.minValue', 0); },
            function (val) { return !val ||
              val <= 100 ||
              _vm.i18n('errors.maxValue', 100); } ]},model:{value:(_vm.value.fees[index - 1].period),callback:function ($$v) {_vm.$set(_vm.value.fees[index - 1], "period", $$v)},expression:"value.fees[index - 1].period"}}),_c('q-input',{staticClass:"field_input_PinCode q-pt-sm col-lg-5 col-md-5 col-sm-5 col-xs-5 q-pr-sm",attrs:{"dense":"","standout":"","clearable":"","no-error-icon":"","bg-color":"white","color":"AstronautBlue","type":"number","min":"0","max":"100","placeholder":_vm.i18n('settings.placeholder.percentage'),"lazy-rules":"","rules":[
            function (val) { return !val ||
              val > -1 ||
              _vm.i18n('errors.minValue', 0); },
            function (val) { return !val ||
              val <= 100 ||
              _vm.i18n('errors.maxValue', 100); } ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"img:/images/icons/ic-percentage-2.svg","size":"1.25rem","color":"black"}})]},proxy:true}],null,true),model:{value:(_vm.value.fees[index - 1].percentage),callback:function ($$v) {_vm.$set(_vm.value.fees[index - 1], "percentage", $$v)},expression:"value.fees[index - 1].percentage"}}),_c('div',{staticClass:"col-lg-1 col-md-1 col-sm-1 col-xs-1 q-pl-sm q-pt-md"},[(index == _vm.value.fees.length)?_c('span',{staticClass:"material-icons-outlined cursor-pointer text-RahalBlue",staticStyle:{"font-size":"1.5rem !important"},on:{"click":function($event){return _vm.addCancellationPeriod()}}},[_vm._v(" add_circle ")]):_c('span',{staticClass:"material-icons-outlined cursor-pointer",staticStyle:{"font-size":"1.5rem !important","color":"red"},on:{"click":function($event){return _vm.removeCancellationPeriod(index - 1)}}},[_vm._v(" remove_circle ")])])],1)])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }