//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { getLanguageCode } from '@/i18n';
// import Message from '@/shared/message/message';

export default {
  name: 'app-saryah-payment-modal',
  props: ['visible', 'value'],

  data() {
    return {
      language: getLanguageCode(),
      // notes: null,
    };
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      // settings:'settings/settings',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapActions({
      // doFindSettings:'settings/doFind',
      // isItemExist:'product/list/isItemExist'
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    doClose() {
      this.$emit('close')
    },
    updateValue(value) {
      this.$emit('input', value);
    },
    async doSubmit() {
      await this.$emit('submit', this.notes)
      this.$emit('close')
    },
  },
};
